body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
#root {
  height: 100%;
  background-color: #f4f4f4;
}

.disabled {
  opacity: 0.4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.front,
.back {
  overflow: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s linear;
  display: flex;
  flex: 1;
}
.front {
  transform: perspective(600px) rotateY(0deg);
}
.back {
  transform: perspective(600px) rotateY(-180deg);
  z-index: 2;
  top: 0px;
  right: 16px;
  bottom: 0px;
  left: 16px;
  box-sizing: content-box;
  position: absolute;
}

.card.rotated > .front {
  transform: perspective(600px) rotateY(180deg);
}
.card.rotated > .back {
  transform: perspective(600px) rotateY(0deg);
}

.card.rotated > .back .widgetBackScrollX {
  overflow: scroll;
}

.card {
  display: flex;
  flex: 1;
  height: 100%;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0 !important;
}

.widgetShadow {
  box-shadow: 1px 1px 1px #000000;
}
.widgetPadding {
  padding: 8px;
}

#sevenInchContainer {
  height: 440px;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropDownContainer {
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  padding: 8px;
}
#filter {
  border: none;
  background-color: transparent;
  width: 100%;
  margin: 16px 0;
}
#filter:focus {
  outline: 2px solid #eeeded; /* Change the color to blue */
}

@media only screen and (min-width: 800px) {
  .rpi7inch {
    display: flex;
  }
  #sevenInchContainer {
    display: flex;
  }
  #mobile {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  body {
    padding-bottom: 64px;
  }

  #mobile {
    display: flex;
    box-sizing: border-box;
  }

  #mainRoot {
    padding: 0px;
  }

  .rpi7inch {
    display: none !important;
  }
  #sevenInchContainer {
    display: none;
  }
}

#statsBar {
  height: 400px !important;
}

#linearGradient-9,
#Cylinder_body,
#Cylinder_body-3,
#Cylinder_body-2,
#Cylinder_body-4 {
  /* fill: linear-gradient(
    286deg,
    rgba(194, 196, 196, 1) 0%,
    rgba(237, 237, 238, 1) 100%
  ); */
  fill: rgba(237, 237, 238, 1);
}

#Shape {
  fill: #d1d0d7;
}

.extraData::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

p {
  user-select: none;
}

.dayButton {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 500;
}

input[type="radio"] {
  display: none;
}
input[type="radio"]:checked + label {
  color: #5aa9f5;
  font-size: 18px;
  font-weight: 500;
}

#priusWidget {
  padding: 0 0px 0 16px;
  box-sizing: border-box;
}
#spotPrice {
  padding-left: 16px;
}

.staging-stripes {
  width: 100%;
  height: 10px;
  bottom: 0;
  position: fixed;

  background: repeating-linear-gradient(
    -55deg,
    #f6ba52,
    #f6ba52 10px,
    #f95454 10px,
    #f95454 20px
  );
}

.value-grid-row:not(:last-child) {
  padding-bottom: 5px;
}

.value-grid-row .value-grid-slot:last-child {
  align-items: flex-end;
}

.value-grid-row .value-grid-slot:last-child > div {
  justify-content: flex-end;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000000;
  color: #000000;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000000;
  color: #000000;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

#mainRoot {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
